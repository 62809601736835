<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Navigation</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents type_noborder">
      <div class="header">Name</div>
      <v-flex class="control">
        <input v-model="name" type="text" placeholder="Data-Table Name" class="type100" />
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">Navigation</div>
      <v-flex class="control">
        <div>
          <div class="type_inner type_tree">
            <v-select
              v-model="phaseCode"
              item-text="phaseName"
              item-value="phaseCode"
              label="Phase"
              placeholder="- Required"
              required
              attach
              :menu-props="{contentClass:'single_select'}"
              :items="phaseOptions"
              :rules="[v => !!v || 'Item is required']"
              @input="onPhaseChanged"
              class="tree_node"
            ></v-select>
            <v-select
              v-model="groupCode"
              item-text="groupName"
              item-value="groupCode"
              label="Menu Group"
              placeholder="- Required"
              required
              attach
              :menu-props="{contentClass:'single_select'}"
              :items="groupOptions"
              :rules="[v => !!v || 'Item is required']"
              @input="onGroupChanged"
              class="tree_node"
            ></v-select>
            <v-select
              v-model="itemCode"
              item-text="itemName"
              item-value="itemCode"
              label="Menu Item"
              placeholder="- Required"
              required
              attach
              :menu-props="{contentClass:'single_select'}"
              :items="itemOptions"
              :rules="[v => !!v || 'Item is required']"
              @input="onItemChanged"
              class="tree_node"
            ></v-select>
            <v-select
              v-model="subItemCode"
              item-text="subItemName"
              item-value="idx"
              label="Sub Item"
              placeholder="- Optional"
              :menu-props="{contentClass:'single_select'}"
              :items="subItemOptions"
              class="tree_end"
              attach
            ></v-select>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">Import Property Data</div>
      <v-flex class="control">
        <div class="column align_end">
          <v-select
            v-model="refcode"
            style="width:20rem;"
            item-text="name"
            item-value="code"
            attach
              :menu-props="{contentClass:'single_select'}"
            :items="importOptions"
          ></v-select>
          <!-- <select v-model="refcode" class="type100">
            <option v-for="item in importOptions" :key="item.index" :value="item.code">{{item.name}}</option>
          </select> -->
          <a class="type01" @click="importItem">Import Data</a>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemCodeService, SystemService } from "@/services"

export default {
  name: 'j-sysenv-tab-general-template',
  props: {
    formMode: String,
  },
  data: () => ({
    dataItem: {},
    dataItemFunc: null,
    importOptionFunc: null,

    // Service Instances -------------
    systemCodeService: null,
    systemService: null,
    // options -----------------------
    phaseOptions: [],
    groupOptions: [],
    itemOptions: [],
    subItemOptions: [],
    // rules -------------------------
    codeRules: [v => (!!v && v != 'ALL') || "Required"],
    defaultRules: [v => !!v || "Required"],
    // Other component owned ---------

    importOptions: [],
    refcode: null,

    // temporal data
    bar3d_general: null,
  }),
  computed: {
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit("sysenv/codePropagate", value) }
    },

    name: {
      get() { return this.dataItem.name },
      set(val) { this.dataItemFunc({ name: val })}
    },
    phaseCode: {
      get() { return this.dataItem.phaseCode },
      set(val) { this.dataItemFunc({ phaseCode: val })}
    },
    groupCode: {
      get() { return this.dataItem.groupCode },
      set(val) { this.dataItemFunc({ groupCode: val })}
    },
    itemCode: {
      get() { return this.dataItem.itemCode },
      set(val) { this.dataItemFunc({ itemCode: val }) }
    },
    subItemCode: {
      get() { return this.dataItem.subItemCode },
      set(val) { this.dataItemFunc({ subItemCode: parseInt(val) }) }
    },
    desc: {
      get() { return this.dataItem.desc },
      set(val) { this.dataItemFunc({ desc: val })}
    },
  },
  created() {
    this.systemCodeService = new SystemCodeService()
    this.systemService = new SystemService()
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = []
      else this.phaseOptions = res
    })
  },
  methods: {
    onPhaseChanged(phaseCode) {
      this.groupOptions = []
      return new Promise(resolve => {
        this.systemService.getNavGroup(phaseCode, res => {
          if (res) this.groupOptions = res
          resolve(res) 
        })
      })
    },
    onGroupChanged(groupCode) {
      this.itemOptions = []
      return new Promise(resolve => {
        this.systemService.getNavItem(
          (res) => {
            if (res) this.itemOptions = res
            resolve(res) 
          },
          this.phaseCode,
          this.groupCode
        )
      })
    },
    onItemChanged(itemCode) {
      this.subItemOptions = [
        {
          idx: 0,
          subItemName: 'None',
        }
      ]

      if (itemCode == "ALL") return
      if (!this.phaseCode || !this.itemCode) return

      return new Promise(resolve => {
        this.systemService.getNavSubItem(
          (res) => {
            if(res) this.subItemOptions = [ ...this.subItemOptions, ...res ]
            resolve(res)
          },
          this.phaseCode,
          this.itemCode
        )
      })
    },

    importItem() {
      if(!this.refcode) return
      this.$emit('import-item', this.refcode)
    },
    setData(mode) {
      if (mode == __C.FORM.EDIT_MODE_NEW) {
        this.phaseCode = this.codePropagated.parentCode
        this.groupCode = this.codePropagated.groupCode
        this.itemCode = this.codePropagated.code

        this.onPhaseChanged(this.codePropagated.parentCode).then(() => {
          this.onGroupChanged(this.codePropagated.groupCode).then(() => {
            this.onItemChanged(this.codePropagated.code)
          })
        })

      } else {
        if(!this.refcode) this.refcode = this.dataItem.idx

        this.onPhaseChanged(this.phaseCode).then(() => {
          this.onGroupChanged(this.groupCode).then(() => {
            this.onItemChanged(this.itemCode)
          })
        })
      }
      
      this.importOptionFunc()
    }
  }
}
</script>
