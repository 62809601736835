<template>
  <v-flex v-model="activator_" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Style</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        Cells <small class="type_ref03 light_blue">(Text/Progress)</small>
        <a class="close" v-on:click="collapsed['cell'] = !collapsed['cell']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['cell']">
        <div class="type_common_input">
          <label>
            Text Margin
            <small class="type_ref03">(Left Or Right)</small>
          </label>
          <input v-model="cellTextMargin" class="type22" type="text"/>
        </div>
        <div>
          <span class="label">Box</span>
          <div class="type_inner">
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">
                Margin
                <small class="type_ref03">(Top/Bottom & Left/Right)</small>
              </label>
              <div class="contents_align_end">
                <input v-model="cellContentsMarginTB" class="type22" type="text"/>
                <input v-model="cellContentsMarginLR" class="type22" type="text"/>
              </div>
            </div>
            <div class="type_common_input type_gray margin_bt_10">
              <label>Radius</label>
              <input v-model="cellBoxRadius" class="type22" type="text" />
            </div>
          </div>
        </div>
        <div class="input_flex_wrap code_title_wrapper">
          <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr('cell')">
            <label>Default Code</label>
          </div>
        </div>
        <div
          class="type_common_input type_color_float"
          v-for="(attr, i) in localStyleAttrs.cell"
          :key="`_cell_${i}`"
        >
          <div class="input_flex_wrap">
            <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn('cell', i)"></div>
            <input class="type_gray w90" v-model="attr.text" type="text" @focusout="updateAttr" />
            <input class="type_30" v-model="attr.code" type="text" @focusout="updateAttr" />
            <j-color-picker v-model="attr.tColor" @input="updateAttr" title>
              <template #action="{ action }">
                <div
                  v-ripple
                  v-on="action.on"
                  class="j_button_color_picker w36"
                  :style="`color: ${attr.tColor}; background: linear-gradient(to right, ${attr.bColor} 50%, #fff 50%); margin-left: 3px;`"
                  @click="action.open"
                >TEXT</div>
              </template>
            </j-color-picker>
            <j-color-picker v-model="attr.bColor" @input="updateAttr" title />
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Color <small class="type_ref03 light_blue">(Text/Background)</small>
        <a class="close" v-on:click="collapsed['color'] = !collapsed['color']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['color']">
        <div class="type_common_input">
          <label>
            Text Margin
            <small class="type_ref03">(Left Or Right)</small>
          </label>
          <input v-model="colorTextMargin" class="type22" type="text"/>
        </div>
        <div>
          <span class="label">Box</span>
          <div class="type_inner">
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">
                Margin
                <small class="type_ref03">(Top/Bottom & Left/Right)</small>
              </label>
              <div class="contents_align_end">
                <input v-model="colorContentsMarginTB" class="type22" type="text"/>
                <input v-model="colorContentsMarginLR" class="type22" type="text"/>
              </div>
            </div>
            <div class="type_common_input type_gray margin_bt_10">
              <label>Radius</label>
              <input v-model="colorBoxRadius" class="type22" type="text" />
            </div>
          </div>
        </div>
        <div class="input_flex_wrap code_title_wrapper">
          <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr('color')">
            <label>Default Code</label>
          </div>
        </div>
        <div
          class="type_common_input type_color_float"
          v-for="(attr, i) in localStyleAttrs.color"
          :key="`_color_${i}`"
        >
          <div class="input_flex_wrap">
            <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn('color', i)"></div>
            <input class="type_gray w90" v-model="attr.text" type="text" @focusout="updateAttr" />
            <input class="type_30  " v-model="attr.code" type="text" @focusout="updateAttr" />
            <j-color-picker v-model="attr.tColor" @input="updateAttr" title>
              <template #action="{ action }">
                <div
                  v-ripple
                  v-on="action.on"
                  class="j_button_color_picker w36"
                  :style="`color: ${attr.tColor}; background-color: ${attr.bColor}; margin-left: 3px;`"
                  @click="action.open"
                >TEXT</div>
              </template>
            </j-color-picker>
            <j-color-picker v-model="attr.bColor" @input="updateAttr" title />
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text <small class="type_ref03 light_blue">(Text Only)</small>
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control small_control" v-show="collapsed['text']">
        <div class="type_common_input margin_bt_10">
          <label>
            Text Margin
            <small class="type_ref03">(Left Or Right)</small>
          </label>
          <input v-model="textTextMargin" class="type22" type="text"/>
        </div>
        <div class="input_flex_wrap code_title_wrapper">
          <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr('text')">
            <label>Default Code</label>
          </div>
        </div>
        <div
          class="type_common_input type_color_float"
          v-for="(attr, i) in localStyleAttrs.text"
          :key="`_color_${i}`"
        >
          <div class="input_flex_wrap">
            <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn('text', i)"></div>
            <input class="type_gray w90" v-model="attr.text" type="text" @focusout="updateAttr" />
            <input class="type_30" v-model="attr.code" type="text" @focusout="updateAttr" />
            <j-color-picker v-model="attr.tColor" @input="updateAttr" title>
              <template #action="{ action }">
                <div
                  v-ripple
                  v-on="action.on"
                  class="j_button_color_picker w36"
                  :style="`color: ${attr.tColor}; background-color: ${attr.bColor}; margin-left: 3px;`"
                  @click="action.open"
                >TEXT</div>
              </template>
            </j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'


export default {
  name: 'j-yssenv-tab-style',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      cell: true,
      color: true,
      text: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localStyleAttrs: {
      cell: [],
      color: [],
      text: [],
    },
    activator_: null,

    msgOpen: false,
    yes: null
  }),
  watch: {
    'datagridItem.styleAttrs': {
      handler(val) {
        if (!val || Object.keys(val).length === 0) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),

    cellTextMargin: {
      get() { return this.localStyleAttrs.cellTextMargin },
      set(val) { this.localStyleAttrs.cellTextMargin = val; this.updateAttr(); },
    },
    cellContentsMarginTB: {
      get() { return this.localStyleAttrs.cellMarginTB },
      set(val) { this.localStyleAttrs.cellMarginTB = val; this.updateAttr(); },
    },
    cellContentsMarginLR: {
      get() { return this.localStyleAttrs.cellMarginLR },
      set(val) { this.localStyleAttrs.cellMarginLR = val; this.updateAttr(); },
    },
    cellBoxRadius: {
      get() { return this.localStyleAttrs.cellBoxRadius },
      set(val) { this.localStyleAttrs.cellBoxRadius = val; this.updateAttr(); },
    },

    colorTextMargin: {
      get() { return this.localStyleAttrs.colorTextMargin },
      set(val) { this.localStyleAttrs.colorTextMargin = val; this.updateAttr(); },
    },
    colorContentsMarginTB: {
      get() { return this.localStyleAttrs.colorMarginTB },
      set(val) { this.localStyleAttrs.colorMarginTB = val; this.updateAttr(); },
    },
    colorContentsMarginLR: {
      get() { return this.localStyleAttrs.colorMarginLR },
      set(val) { this.localStyleAttrs.colorMarginLR = val; this.updateAttr(); },
    },
    colorBoxRadius: {
      get() { return this.localStyleAttrs.colorBoxRadius },
      set(val) { this.localStyleAttrs.colorBoxRadius = val; this.updateAttr(); },
    },

    textTextMargin: {
      get() { return this.localStyleAttrs.textTextMargin },
      set(val) { this.localStyleAttrs.textTextMargin = val; this.updateAttr(); },
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['setDatagridItem']),

    appendAttr(typeName) {
      this.localStyleAttrs[typeName].push({ text: '', code: '', bColor: '', tColor: '' })
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(typeName, index) {
      this.localStyleAttrs[typeName][index] = ''
      this.localStyleAttrs[typeName] = this.localStyleAttrs[typeName].filter(v => !!v)
      this.updateAttr()
    },
    updateAttr() {
      this.setDatagridItem({ styleAttrs: this.localStyleAttrs })
    }
  }
}
</script>
