<template>
  <v-menu
    v-model="iconPickerOpen"
    content-class="j_picker icon_picker"
    :close-on-content-click="false"
    :min-width="300"
    :nudge-top="`${this.offsetX ? 329 : 0}`"
    :nudge-right="`${this.offsetX ? 10 : 0}`"
    :nudge-left="`${this.offsetY ? 32 : 0}`"
    :offset-y="this.offsetY"
    :offset-x="this.offsetX"
  >
    <template v-slot:activator="{ on }">
      <slot name="action" :action="{ on: on }">
        <div class="picker type_icon">
          <span class="label">Icon</span>
          <v-btn v-on="on" :style="styleActivator" @click="popIconPicker(!iconPickerOpen)" />
        </div>
      </slot>
    </template>
    <v-card :class="`${ this.offsetX ? 'right_side' : null}`">
      <div class="j_picker__header">
        <span>
          SVG Icon Picker
          <small>SVG</small>
        </span>
      </div>
      <div class="j_picker__contents">
        <div class="current_icon">
          <div :key="iconPickerOpen">
            <button @click="removeIcon">
              <v-icon>mdi-close</v-icon>
            </button>
            <img v-if="icon.filePath" :src="`${hostName}${icon.filePath}`" />
          </div>
          <span>{{icon.fileName}}</span>
        </div>
        <div class="select_icon">
          <button
            v-for="icon in iconList"
            :value="icon.name"
            :key="icon.iconCode"
            @click="selectIcon(icon)"
          >
            <img :src="`${hostName}${icon.filePath}`" />
          </button>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemService } from "@/services"

export default {
  name: "j-icon-picker",
  props: {
    offsetX: { type: Boolean, default: false },
    offsetY: { type: Boolean, default: false },
    catCode: { type: String, default: "SVG" },
    reRender: { type: String },
    value: { type: [String, Object] },
  },
  data: () => ({
    iconPopoverShow: false,
    iconList: [],
    icon: {},
    iconPickerOpen: false
  }),
  watch: {
    value: {
      handler(val) {
        if(!val) return
        
        if(typeof val == 'string') this.systemService.getIcon(val, res => { if (!res) res = []; this.selectIcon(res) })
        else if(typeof val == 'object') this.icon = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },
    reRender: {
      handler(val) {
        if (val !== void 0 && typeof this.value === 'object') this.icon = this.value
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    styleActivator() { 
      return (
        this.icon && this.icon.filePath ? 
        `background-image: url(${this.hostName}${this.icon.filePath}); background-size: 100% 100%;` :
        '' 
      )
    },
    hostName() { return __C.HOST_NAME }
  },
  created() {
    this.systemService = new SystemService()
    if(this.value && typeof this.value == 'string') {
      this.systemService.getIcon(
        this.value, 
        (res) => {
          if (!res) res = []
          this.selectIcon(res)
        }
      )
    }
  },
  methods: {
    selectIcon(icon) {
      this.icon = icon
      this.$emit('input', this.icon)
    },
    removeIcon(e) {
      this.icon = {}
      this.$emit('input', this.icon)
    },
    popIconPicker(opened) {
      if (!opened) return
      this.systemService.getIcons(this.catCode, res => {
        if (!res) res = []
        this.iconList = res
      })
    },
  }
}
</script>

<style>
</style>