<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Body</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <label class="type_gray">
            Text Margin
            <small class="type_ref03">(Left Or Right)</small>
          </label>
          <input v-model="bodyTextMargin" class="type30" type="text"/>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Summary Row
        <a class="close" v-on:click="collapsed['row'] = !collapsed['row']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['row']">
        <div class="type_common_input">
          <label class="type_gray">Line Height</label>
          <input v-model="tableSummaryLineHeight" class="type30" type="text"/>
        </div>
        <div class="type_common_select">
          <label class="type_gray">Font</label>
          <select v-model="tableSummaryFont" dir="rtl" >
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label class="type_gray">Font Style</label>
          <select v-model="tableSummaryFontStyle" dir="rtl" class="type70" >
            <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label class="type_gray">Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="tableSummaryFontSize" type="text" class="type30"/>
            <j-color-picker v-model="tableSummaryFontColor" />
          </div>
        </div>
        <div class="type_common_input type_color_float hide_last_child">
          <label class="type_gray">Background Color</label>
          <j-color-picker v-model="tableSummaryBgColor" ></j-color-picker>
        </div>
        <div>
          <span class="label">Border Bottom</span>
          <div class="type_inner">
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Stroke / Color</label>
              <div class="input_flex_wrap_end">
                <input class="type30" type="number" :value="getSumBorderBottomWidth()" @input="(e) => { setSumBorderBottomWidth(e.target.value) }" />
                <j-color-picker :value="getSumBorderBottomColor()" @input="(v) => { setSumBorderBottomColor(v) }" ></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_gray">
              <label>Opacity</label>
              <input class="type30" type="number" :value="getSumBorderBottomOpacity()" @input="(e) => { setSumBorderBottomOpacity(e.target.value) }" />
            </div>
          </div>
        </div>
        <div v-for="(attrs, i) in SumProperies" :key="`_header_sum_${i}`">
          <span v-if="i === 0" data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" @click="appendAttr('summary')">1st</span>
          <span v-else data-grid-quickbtn="btn" class="label jcon_temp_minus type_header" @click="clickRemoveBtn('summary', i)">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div>
              <span class="label">Border</span>
              <div class="type_inner">
                <div class="type_common_input type_color_float">
                  <label class="type_gray second_depth">Stroke / Color</label>
                  <div class="input_flex_wrap_end">
                    <input class="type30" type="number" :value="getSumBorderWidth(i)" @input="(e) => { setSumBorderWidth(e.target.value, i) }" />
                    <j-color-picker :value="getSumBorderColor(i)" @input="(v) => { setSumBorderColor(v, i) }" ></j-color-picker>
                  </div>
                </div>
                <div class="type_common_input type_gray">
                  <label>Opacity</label>
                  <input class="type30" type="number" :value="getSumBorderOpacity(i)" @input="(e) => { setSumBorderOpacity(e.target.value, i) }" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Cell Properties
        <a class="close" v-on:click="collapsed['cell'] = !collapsed['cell']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['cell']">
        <div class="type_common_input">
          <label class="type_gray">Line Height</label>
          <input v-model="tableCellsLineHeight" class="type30" type="text" />
        </div>
        <div class="type_common_select">
          <label class="type_gray">Font</label>
          <select v-model="tableCellsFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label class="type_gray">Font Style</label>
          <select v-model="tableCellsFontStyle" dir="rtl" class="type70">
            <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label class="type_gray">Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="tableCellsFontSize" type="text" class="type30" />
            <j-color-picker v-model="tableCellsFontColor"></j-color-picker>
          </div>
        </div>
        <div v-for="(attrs, i) in cellProperies" :key="`_header_${i}`">
          <span v-if="i === 0" data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" @click="appendAttr('cell')">1st</span>
          <span v-else data-grid-quickbtn="btn" class="label jcon_temp_minus type_header" @click="clickRemoveBtn('cell', i)">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div>
              <span class="label">Border</span>
              <div class="type_inner">
                <div class="type_common_input type_color_float">
                  <label class="type_gray second_depth">Stroke / Color</label>
                  <div class="input_flex_wrap_end">
                    <input class="type30" type="number" :value="getCellBorderWidth(i)" @input="(e) => { setCellBorderWidth(e.target.value, i) }" />
                    <j-color-picker :value="getCellBorderColor(i)" @input="(v) => { setCellBorderColor(v, i) }" ></j-color-picker>
                  </div>
                </div>
                <div class="type_common_input type_gray">
                  <label>Opacity</label>
                  <input class="type30" type="number" :value="getCellBorderOpacity(i)" @input="(e) => { setCellBorderOpacity(e.target.value, i) }" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <!-- <div class="chart_properties_contents">
      <div class="header">
        Merge
        <a class="close" v-on:click="collapsed['merge'] = !collapsed['merge']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['merge']">
        <div class="type_common_input">
          <span class="label">Applicable</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="tableMergeApply"
                type="radio"
                name="tableMergeApply"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Freeze
        <a class="close" v-on:click="collapsed['freeze'] = !collapsed['freeze']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['freeze']">
        <div class="type_common_input">
          <span class="label">Applicable</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="tableFreezeApply"
                type="radio"
                name="tableFreezeApply"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
          <div class="type_common_input">
          <label class="type_gray">Column Number</label>
          <input v-model="tableFreezeColNum" class="type30" type="text"/>
        </div>
        <div class="type_common_input type_color_float">
          <label class="type_gray">Border / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="tableFreezeBorderSize" type="text" class="type30"/>
            <j-color-picker v-model="tableFreezeBorderColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Summary Row</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="tableFreezeSummaryRow"
                type="radio"
                name="tableFreezeSummaryRow"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div> -->
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import __F from '@/primitives/_function_'
import _Dataform from '@/primitives/_dataformDatatable'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-body',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
      row: true,
      cell: true,
      merge: true,
      freeze: true,
    },

    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },

    localBodyAttrs: {},

    msgOpen: false,
    yes: null
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),
    
    cellProperies() { 
      return this.localBodyAttrs.cell.styles ? this.localBodyAttrs.cell.styles : []
    },
    SumProperies() { 
      return this.localBodyAttrs.summary.styles ? this.localBodyAttrs.summary.styles : []
    },

    bodyTextMargin: {
      get() { return this.localBodyAttrs.textMargin },
      set(val) { this.localBodyAttrs.textMargin = val; this.updateAttr(); }
    },
    tableSummaryLineHeight: {
      get() { return this.localBodyAttrs.summary.height },
      set(val) { this.localBodyAttrs.summary.height = val; this.updateAttr(); }
    },
    tableSummaryFont: {
      get() { return this.localBodyAttrs.summary.font },
      set(val) { this.localBodyAttrs.summary.font = val; this.updateAttr(); }
    },
    tableSummaryFontStyle: {
      get() { return this.localBodyAttrs.summary.fontStyle },
      set(val) { this.localBodyAttrs.summary.fontStyle = val; this.updateAttr(); }
    },
    tableSummaryFontSize: {
      get() { return this.localBodyAttrs.summary.fontSize },
      set(val) { this.localBodyAttrs.summary.fontSize = val; this.updateAttr(); }
    },
    tableSummaryFontColor: {
      get() { return this.localBodyAttrs.summary.color },
      set(val) { this.localBodyAttrs.summary.color = val; this.updateAttr(); }
    },
    tableSummaryBgColor: {
      get() { return this.localBodyAttrs.summary.bColor },
      set(val) { this.localBodyAttrs.summary.bColor = val; this.updateAttr(); }
    },
    tableSummaryBorderSize: {
      get() { return this.localBodyAttrs.summary.borderSize },
      set(val) { this.localBodyAttrs.summary.borderSize = val; this.updateAttr(); }
    },
    tableSummaryBorderColor: {
      get() { return this.localBodyAttrs.summary.borderColor },
      set(val) { this.localBodyAttrs.summary.borderColor = val; this.updateAttr(); }
    },
    tableCellsLineHeight: {
      get() { return this.localBodyAttrs.cell.height },
      set(val) { this.localBodyAttrs.cell.height = val; this.updateAttr(); }
    },
    tableCellsFont: {
      get() { return this.localBodyAttrs.cell.font },
      set(val) { this.localBodyAttrs.cell.font = val; this.updateAttr(); }
    },
    tableCellsFontStyle: {
      get() { return this.localBodyAttrs.cell.fontStyle },
      set(val) { this.localBodyAttrs.cell.fontStyle = val; this.updateAttr(); }
    },
    tableCellsFontSize: {
      get() { return this.localBodyAttrs.cell.fontSize },
      set(val) { this.localBodyAttrs.cell.fontSize = val; this.updateAttr(); }
    },
    tableCellsFontColor: {
      get() { return this.localBodyAttrs.cell.color },
      set(val) { this.localBodyAttrs.cell.color = val; this.updateAttr(); }
    },
    tableMergeApply: {
      get() { return this.localBodyAttrs.merge },
      set(val) { this.localBodyAttrs.merge = val; this.updateAttr(); }
    },
    tableFreezeApply: {
      get() { return this.localBodyAttrs.freeze.applicable },
      set(val) { this.localBodyAttrs.freeze.applicable = val; this.updateAttr(); }
    },
    tableFreezeSummaryRow: {
      get() { return this.localBodyAttrs.freeze.summaryRow },
      set(val) { this.localBodyAttrs.freeze.summaryRow = val; this.updateAttr(); }
    },
    tableFreezeColNum: {
      get() { return this.localBodyAttrs.freeze.columnNo },
      set(val) { this.localBodyAttrs.freeze.columnNo = val; this.updateAttr(); }
    },
    tableFreezeBorderColor: {
      get() { return this.localBodyAttrs.freeze.borderColor },
      set(val) { this.localBodyAttrs.freeze.borderColor = val; this.updateAttr(); }
    },
    tableFreezeBorderSize: {
      get() { return this.localBodyAttrs.freeze.borderWeight },
      set(val) { this.localBodyAttrs.freeze.borderWeight = val; this.updateAttr(); }
    },
  },
  watch: {
    'datagridItem.bodyAttrs': {
      handler(val) {
        if(!val || JSON.stringify(val) == JSON.stringify(this.localBodyAttrs)) return

        if(Object.keys(val).length === 0) this.localBodyAttrs = _Dataform.body
        else this.localBodyAttrs = JSON.parse(JSON.stringify(val))

        if(!this.localBodyAttrs.summary.border) this.localBodyAttrs.summary.border = { width: 1, color: 'rgba(207, 216, 220, 1)' }
        
        this.updateAttr()
      },
      deep: true
    }
  },
  created() {
    this.localBodyAttrs = _Dataform.body
    this.updateAttr()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['setDatagridItem']),

    // ---------------
    appendAttr(name) {
      if(this.localBodyAttrs[name].styles.length === 0) var arrtStyle_ = _Dataform.body[name].styles[0]
      else arrtStyle_ = JSON.parse(JSON.stringify(this.localBodyAttrs[name].styles[this.localBodyAttrs[name].styles.length-1]))

      this.localBodyAttrs[name].styles.push(arrtStyle_) 
      this.updateAttr()
    },
    removeAttr(name, index) {
      this.localBodyAttrs[name].styles[index] = null
      this.localBodyAttrs[name].styles = this.localBodyAttrs[name].styles.filter(style => !!style)
      this.updateAttr()
    },
    updateAttr() {
      this.setDatagridItem({ bodyAttrs: JSON.parse(JSON.stringify(this.localBodyAttrs)) })
    },

    // ### Summary Styles ---------------
    getSumBorderBottomWidth() { return this.localBodyAttrs.summary.border.width },
    setSumBorderBottomWidth(val) {
      this.localBodyAttrs.summary.border.width = val
      this.updateAttr()
    },
    getSumBorderBottomColor() { return __F.rgba2hex(this.localBodyAttrs.summary.border.color).hex },
    setSumBorderBottomColor(val) {
      if(val == 'transparent') {
        this.setSumBorderBottomWidth(0)
        this.setSumBorderBottomOpacity(0)
        return
      }
      this.localBodyAttrs.summary.border.color = __F.hex2rgba(val, this.getSumBorderBottomOpacity()); 
      this.updateAttr()
    },
    getSumBorderBottomOpacity() { return __F.rgba2hex(this.localBodyAttrs.summary.border.color).alpha },
    setSumBorderBottomOpacity(val) {
      this.localBodyAttrs.summary.border.color = __F.hex2rgba(this.getSumBorderBottomColor(), val)
      this.updateAttr()
    },

    getSumBorderWidth(i) { return this.localBodyAttrs.summary.styles[i].border.width },
    setSumBorderWidth(val, i) {
      this.localBodyAttrs.summary.styles[i].border.width = val
      this.updateAttr()
    },
    getSumBorderColor(i) { return __F.rgba2hex(this.localBodyAttrs.summary.styles[i].border.color).hex },
    setSumBorderColor(val, i) {
      if(val == 'transparent') {
        this.setSumBorderWidth(0, i)
        this.setSumBorderOpacity(0, i)
        return
      }
      this.localBodyAttrs.summary.styles[i].border.color = __F.hex2rgba(val, this.getSumBorderOpacity(i)); 
      this.updateAttr()
    },
    getSumBorderOpacity(i) { return __F.rgba2hex(this.localBodyAttrs.summary.styles[i].border.color).alpha },
    setSumBorderOpacity(val, i) {
      this.localBodyAttrs.summary.styles[i].border.color = __F.hex2rgba(this.getSumBorderColor(i), val)
      this.updateAttr()
    },
    // ### Cell Styles ------------------
    getCellBorderWidth(i) { return this.localBodyAttrs.cell.styles[i].border.width },
    setCellBorderWidth(val, i) {
      this.localBodyAttrs.cell.styles[i].border.width = val
      this.updateAttr()
    },
    getCellBorderColor(i) { return __F.rgba2hex(this.localBodyAttrs.cell.styles[i].border.color).hex },
    setCellBorderColor(val, i) {
      if(val == 'transparent') {
        this.setCellBorderWidth(0, i)
        this.setCellBorderOpacity(0, i)
        return
      }
      this.localBodyAttrs.cell.styles[i].border.color = __F.hex2rgba(val, this.getCellBorderOpacity(i)); 
      this.updateAttr()
    },
    getCellBorderOpacity(i) { return __F.rgba2hex(this.localBodyAttrs.cell.styles[i].border.color).alpha },
    setCellBorderOpacity(val, i) {
      this.localBodyAttrs.cell.styles[i].border.color = __F.hex2rgba(this.getCellBorderColor(i), val)
      this.updateAttr()
    },
    // ---------------

    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    }
  }
}
</script>
