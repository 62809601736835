<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Header</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
          v-model="msgOpen"
          :title="msgInfo.title"
          :type="msgInfo.type"
          :message="msgInfo.message"
          :button="msgInfo.button"
          :button-text="msgInfo.buttonText"
          @yes="yes()"
          @cancel="msgOpen = false"
        />
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_select">
          <label>Font Family</label>
          <select v-model="headerFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>
            Base Color
            <small class="type_ref03">(Background)</small>
          </label>
          <div class="input_flex_wrap_end">
            <j-color-picker 
              v-model="headerBaseColor"
              gradation
              :gradation-type="headerBaseColorGradType"
              @gradate="v => headerBaseColorGradType = v"
            ></j-color-picker>
          </div>
        </div>
        <div>
          <span class="label">Border Bottom</span>
          <div class="type_inner">
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Stroke / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="headerBorderWeight" class="type30" type="number" />
                <j-color-picker v-model="headerBorderColor"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_gray margin_bt_10">
              <label>Opacity</label>
              <input v-model="headerBorderOpacity" class="type30" type="number" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Cell Properties
        <a class="close" v-on:click="collapsed['cell'] = !collapsed['cell']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['cell']">
        <div v-for="(attrs, i) in attrEls" :key="`_header_${i}`">
          <span v-if="i === 0" data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" @click="appendAttr()">1st</span>
          <span v-else data-grid-quickbtn="btn" class="label jcon_temp_minus type_header margin_top_10" @click="clickRemoveBtn(i)">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label>Height</label>
              <input v-model="attrs.height" class="type30" type="text" @input="updateAttr" />
            </div>
            <div class="type_common_select">
              <label>Font Style</label>
              <select dir="rtl" class="type70" :value="attrs.fontStyle" @input="(e) => { updateAttr(e.target.value, i, 'fontStyle') }">
                <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label>
                Font 
                <small class="type_ref03">(Size / Color)</small>
              </label>
              <div class="input_flex_wrap_end">
                <input v-model="attrs.fontSize" type="text" class="type30" @input="updateAttr" />
                <j-color-picker v-model="attrs.tColor" @input="updateAttr"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_color_float">
              <label>
                Fill
                <small class="type_ref03">(Opacity / Color)</small>
              </label>
              <div class="input_flex_wrap_end">
                <input v-model="attrs.background.opacity" type="number" class="type30" @input="updateAttr" />
                <j-color-picker 
                  v-model="attrs.background.color"
                  gradation
                  :gradation-type="attrs.background.gradient"
                  @input="updateAttr"
                  @gradate="(v) => { updateAttr(v, i, 'background.gradient') }"
                ></j-color-picker>
              </div>
            </div>
            <div>
              <span class="label">Border</span>
              <div class="type_inner">
                <div class="type_common_input type_color_float">
                  <label class="type_gray second_depth">Stroke / Color</label>
                  <div class="input_flex_wrap_end">
                    <input class="type30" type="number" :value="getCellBorderWidth(i)" @input="(e) => { setCellBorderWidth(e.target.value, i) }" />
                    <j-color-picker :value="getCellBorderColor(i)" @input="(v) => { setCellBorderColor(v, i) }" ></j-color-picker>
                  </div>
                </div>
                <div class="type_common_input type_gray">
                  <label>Opacity</label>
                  <input class="type30" type="number" :value="getCellBorderOpacity(i)" @input="(e) => { setCellBorderOpacity(e.target.value, i) }" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import __F from '@/primitives/_function_'
import _Dataform from '@/primitives/_dataformDatatable'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-header',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
      cell: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localHeaderAttrs: {},

    msgOpen: false,
    yes: null
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),

    attrEls() { 
      return this.localHeaderAttrs.styles ? this.localHeaderAttrs.styles : []
    },

    headerFont: {
      get() { return this.localHeaderAttrs.font },
      set(val) { this.localHeaderAttrs.font = val; this.updateAttr(); }
    },
    headerBaseColor: {
      get() { return this.localHeaderAttrs.background.color },
      set(val) { this.localHeaderAttrs.background.color = val; this.updateAttr(); }
    },
    headerBaseColorGradType: {
      get() { return this.localHeaderAttrs.background.gradient },
      set(val) { this.localHeaderAttrs.background.gradient = val; this.updateAttr(); }
    },
    headerBaseColorOpacity: {
      get() { return this.localHeaderAttrs.background.opacity },
      set(val) { this.localHeaderAttrs.background.opacity = val; this.updateAttr(); }
    },
    headerBorderWeight: {
      get() { return this.localHeaderAttrs.border.width },
      set(val) { this.localHeaderAttrs.border.width = val; this.updateAttr(); }
    },
    headerBorderColor: {
      get() { return __F.rgba2hex(this.localHeaderAttrs.border.color).hex },
      set(val) { 
        if(val == 'transparent') {
          this.headerBorderWeight = 0
          this.headerBorderOpacity = 0
          return
        }
        this.localHeaderAttrs.border.color = __F.hex2rgba(val, this.headerBorderOpacity); 
        this.updateAttr()
      }
    },
    headerBorderOpacity: {
      get() { return __F.rgba2hex(this.localHeaderAttrs.border.color).alpha },
      set(val) { this.localHeaderAttrs.border.color = __F.hex2rgba(this.headerBorderColor, val); this.updateAttr(); }
    },
  },
  watch: {
    'datagridItem.headerAttrs': {
      handler(val) {
        if(!val || JSON.stringify(val) == JSON.stringify(this.localHeaderAttrs)) return

        if(Object.keys(val).length === 0) this.localHeaderAttrs = _Dataform.header
        else this.localHeaderAttrs = JSON.parse(JSON.stringify(val))
        
        this.updateAttr()
      },
      deep: true
    }
  },
  created() {
    this.localHeaderAttrs = _Dataform.header
    this.updateAttr()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['setDatagridItem']),

    // ---------------
    appendAttr() {
      if(this.localHeaderAttrs.styles.length === 0) var arrtStyle_ = _Dataform.header.styles[0]
      else arrtStyle_ = JSON.parse(JSON.stringify(this.localHeaderAttrs.styles[this.localHeaderAttrs.styles.length-1]))

      this.localHeaderAttrs.styles.push(arrtStyle_)
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index) {
      this.localHeaderAttrs.styles[index] = null
      this.localHeaderAttrs.styles = this.localHeaderAttrs.styles.filter(style => !!style)
      this.updateAttr()
    },
    updateAttr(val, i, el) {
      if(el) {
        if(el == 'background.gradient') this.localHeaderAttrs.styles[i].background.gradient = val
        else this.localHeaderAttrs.styles[i][el] = val
      }
      this.setDatagridItem({ headerAttrs: JSON.parse(JSON.stringify(this.localHeaderAttrs)) })
    },
    // ---------------

    // ---------------
    getCellBorderWidth(i) { return this.localHeaderAttrs.styles[i].border.width },
    setCellBorderWidth(val, i) {
      this.localHeaderAttrs.styles[i].border.width = val
      this.updateAttr()
    },
    getCellBorderColor(i) { return __F.rgba2hex(this.localHeaderAttrs.styles[i].border.color).hex },
    setCellBorderColor(val, i) {
      if(val == 'transparent') {
        this.setCellBorderWidth(0, i)
        this.setCellBorderOpacity(0, i)
        return
      }
      this.localHeaderAttrs.styles[i].border.color = __F.hex2rgba(val, this.getCellBorderOpacity(i)); 
      this.updateAttr()
    },
    getCellBorderOpacity(i) { return __F.rgba2hex(this.localHeaderAttrs.styles[i].border.color).alpha },
    setCellBorderOpacity(val, i) {
      this.localHeaderAttrs.styles[i].border.color = __F.hex2rgba(this.getCellBorderColor(i), val)
      this.updateAttr()
    },
    // ---------------

    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    }
  }
}
</script>
