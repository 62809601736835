
import JSysenvTabGeneral from './JSysenvTabGeneral'
import JSysenvTabDatabase from './JSysenvTabDatabase'
import JSysenvTabTable from './JSysenvTabTable'
import JSysenvTabTitle from './JSysenvTabTitle'
import JSysenvTabTimeline from './JSysenvTabTimeline'
import JSysenvTabGroup from './JSysenvTabGroup'
import JSysenvTabStyle from './JSysenvTabStyle'
import JSysenvTabSvg from './JSysenvTabSvg'
import JSysenvTabHeader from './JSysenvTabHeader'
import JSysenvTabBody from './JSysenvTabBody'

export default {
  JSysenvTabGeneral,
  JSysenvTabDatabase,
  JSysenvTabTable,
  JSysenvTabTitle,
  JSysenvTabTimeline,
  JSysenvTabGroup,
  JSysenvTabStyle,
  JSysenvTabSvg,
  JSysenvTabHeader,
  JSysenvTabBody,
}
