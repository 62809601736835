<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>SVG</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        Bar
        <a class="close" v-on:click="collapsed['bar'] = !collapsed['bar']"></a>
      </div>
      <v-flex class="control cell" v-show="collapsed['bar']">
        <div class="type_common_input">
          <label>
            Box Margin
            <small class="type_ref03">(T/B & L/R)</small>
          </label>
          <div class="contents_align_end">
            <input v-model="barMarginTB" class="type22" type="text"/>
            <input v-model="barMarginLR" class="type22" type="text"/>
          </div>
        </div>
        <div class="type_common_input">
          <label>Width</label>
          <input v-model="barWidth" type="number" />
        </div>
        <div class="type_common_input">
          <label>Thickness</label>
          <input v-model="barThickness" type="number" />
        </div>
        <div class="type_common_input">
          <label>Radius</label>
          <input v-model="barRadius" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="barBackground" @input="updateAttr" title="Background"></j-color-picker>
        </div>
        <div class="type_common_input column margin_bt_10">
          <span class="label">Text</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Unit</span>
              <input v-model="barTextUnit" type="text" class="type22" @input="updateAttr" />
            </div>
            <div class="type_common_input type_gray">
              <span class="label">Alignment</span>
            </div>
          </div>
          <div class="row justify_end">
            <label class="type_many">
              <input
                v-model="barTextAlign"
                type="radio"
                name="barTextAlign"
                value="auto"
                @input="updateAttr"
              />
              Auto
            </label>
            <label v-for="option in alignHOptions" :key="option.index" class="type_many">
              <input
                v-model="barTextAlign"
                type="radio"
                name="barTextAlign"
                :value="option.value"
                @input="updateAttr"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div>
          <label>Code <small class="type_ref03">(Text/Bar)</small></label>
          <div class="type_inner">
            <div class="input_flex_wrap code_title_wrapper">
              <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr('bar')">
                <label>Default Code</label>
              </div>
            </div>
            <div
              class="type_common_input type_color_float"
              v-for="(attr, i) in localSvgAttrs.bar.attrs"
              :key="`_bar_${i}`"
            >
              <div class="input_flex_wrap">
                <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn('bar', i)"></div>
                <input class="type_gray w90" v-model="attr.text" type="text" @focusout="updateAttr" />
                <input class="type_30 " v-model="attr.code" type="text" @focusout="updateAttr" />
                <j-color-picker v-model="attr.tColor" @input="updateAttr" title>
                  <template #action="{ action }">
                    <div
                      v-ripple
                      v-on="action.on"
                      class="j_button_color_picker w32"
                      style="display: flex; align-items: center; margin-left: 3px;"
                      @click="action.open"
                    >
                      <div
                        class="bar"
                        :style="`width: 21px; height: 12px; margin-right: 3px; background-color: ${attr.bColor}; border-radius: ${barRadius}px`"
                      ></div>
                      <span :style="`font-size: 9px; color: ${attr.tColor}`">27%</span>
                    </div>
                  </template>
                </j-color-picker>
                <j-color-picker v-model="attr.bColor" @input="updateAttr"/>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Icon
        <a class="close" v-on:click="collapsed['icon'] = !collapsed['icon']"></a>
      </div>
      <v-flex class="control color" v-show="collapsed['icon']">
        <div class="type_common_input">
          <span class="label">Size</span>
          <input v-model="iconSize" type="text" @input="updateAttr" />
        </div>
        <div class="type_common_input column margin_bt_10">
          <span class="label">Text Position</span>
          <div class="row justify_end">
            <label class="type_many">
              <input
                v-model="iconTextPosition"
                type="radio"
                name="iconTextPosition"
                value="left"
                @input="updateAttr"
              /> Left
            </label>
            <label class="type_many">
              <input
                v-model="iconTextPosition"
                type="radio"
                name="iconTextPosition"
                value="right"
                @input="updateAttr"
              /> Right
            </label>
            <label class="type_many">
              <input
                v-model="iconTextPosition"
                type="radio"
                name="iconTextPosition"
                value="n/a"
                @input="updateAttr"
              /> N/A
            </label>
          </div>
        </div>
        <div>
          <label>Code <small class="type_ref03">(SVG Icon)</small></label>
        </div>
        <div>
          <div class="type_small_input">
            <div class="input_flex_wrap code_title_wrapper">
              <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr('icon')">
                <label>Default Code</label>
              </div>
            </div>
            <div
              class="type_common_input type_color_float"
              v-for="(attr, i) in localSvgAttrs.icon.attrs"
              :key="`_bar_${i}`"
            >
              <div class="input_flex_wrap">
                <div
                  class="jcon_temp_minus"
                  data-grid-quickbtn="btn"
                  @click="clickRemoveBtn('icon', i)"
                ></div>
                <input v-model="attr.text" class="type_gray w90" type="text" @focusout="updateAttr" />
                <input class="type_30 " v-model="attr.code" type="text" @focusout="updateAttr" />
                <j-icon-picker v-model="attr.file" cat-code="SVG" :offset-y="true" @input="updateAttr" />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'
import JIconPicker from "@/components/floating/JIconPicker"

export default {
  name: 'j-chart-tab-svg',
  mixins: [
    StaticOptions
  ],
  components: {
    JIconPicker
  },
  data: () => ({
    collapsed: {
      bar: true,
      icon: true,
    },
    bar_general: null,
    currentIcon: null,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localSvgAttrs: {
      marginTB: 0,
      marginLR: 0,
      bar: {
        width: 0,
        thickness: 0,
        radius: 0,
        background: '#EFEFEF',
        textUnit: '%',
        textAlign: 'auto',   // auto | left | center | right
        attrs: [],
      },
      icon: {
        size: 14,
        textPositoin: '',   // left | right | n/a
        attrs: []
      },
    },

    msgOpen: false,
    yes: null
  }),
  watch: {
    'datagridItem.svgAttrs': {
      handler(val) {
        if (!val || Object.keys(val).length === 0) return
        this.localSvgAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),

    barMarginTB: {
      get() { return this.localSvgAttrs.marginTB },
      set(val) { this.localSvgAttrs.marginTB = val; this.updateAttr(); },
    },
    barMarginLR: {
      get() { return this.localSvgAttrs.marginLR },
      set(val) { this.localSvgAttrs.marginLR = val; this.updateAttr(); },
    },

    barWidth: {
      get() { return this.localSvgAttrs.bar.width },
      set(val) {
        this.localSvgAttrs.bar.width = val
        this.updateAttr()
      }
    },
    barThickness: {
      get() { return this.localSvgAttrs.bar.thickness },
      set(val) {
        this.localSvgAttrs.bar.thickness = val
        this.updateAttr()
      }
    },
    barRadius: {
      get() { return this.localSvgAttrs.bar.radius },
      set(val) {
        this.localSvgAttrs.bar.radius = val
        this.updateAttr()
      }
    },
    barBackground: {
      get() { return this.localSvgAttrs.bar.background },
      set(val) {
        this.localSvgAttrs.bar.background = val
        this.updateAttr()
      }
    },
    barTextUnit: {
      get() { return this.localSvgAttrs.bar.textUnit },
      set(val) {
        this.localSvgAttrs.bar.textUnit = val
        this.updateAttr()
      }
    },
    barTextAlign: {
      get() { return this.localSvgAttrs.bar.textAlign },
      set(val) {
        this.localSvgAttrs.bar.textAlign = val
        this.updateAttr()
      }
    },
    iconSize: {
      get() { return this.localSvgAttrs.icon.size },
      set(val) {
        this.localSvgAttrs.icon.size = val
        this.updateAttr()
      }
    },
    iconTextPosition: {
      get() { return this.localSvgAttrs.icon.textPosition },
      set(val) {
        this.localSvgAttrs.icon.textPosition = val
        this.updateAttr()
      }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['setDatagridItem']),

    appendAttr(typeName) {
      this.localSvgAttrs[typeName].attrs.push(
        typeName == 'bar' ?
          { text: '', code: '', bColor: '', tColor: '' } :
          { text: '', code: '', bColor: '', tColor: '', file: {} }
      )
      this.setDatagridItem({ svgAttrs: this.localSvgAttrs })
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(typeName, index) {
      this.localSvgAttrs[typeName].attrs[index] = ''
      this.localSvgAttrs[typeName].attrs = this.localSvgAttrs[typeName].attrs.filter(v => !!v)
      this.setDatagridItem({ svgAttrs: this.localSvgAttrs })
    },
    updateAttr() {
      this.setDatagridItem({ svgAttrs: this.localSvgAttrs })
    },
  }
}
</script>
