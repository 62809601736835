<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Database</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Data Source
        <a class="close" v-on:click="collapsed['source'] = !collapsed['source']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['source']">
        <div class="type_common_input">
          <span class="label">Use Direct Access</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="directAccess" type="radio" name="directAccess" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div v-show="isDirectAccess" class="type_inner">
          <div class="type_common_input type_gray">
            <label>Database</label>
            <select v-model="dbName" @input="(e) => { databaseChanged(e.target.value) }">
              <option 
                v-for="option in databaseOptions" :key="option.index" 
                :value="option.dbName"
              >{{option.dbName}}</option>
            </select>
          </div>
          <div class="type_common_input type_gray">
            <label>Table/View</label>
            <select v-model="tableName" @input="(e) => { tableChanged(e.target.value) }">
              <option 
                v-for="option in tableOptions" :key="option.index" 
                :value="option.tableName"
              >{{option.schemaName}}.{{option.tableName}}</option>
            </select>
          </div>
        </div>
        <v-spacer v-show="isDirectAccess" style="height: 10px;"></v-spacer>
        <div class="type_common_input">
          <span class="label">Run SQL {{ isDirectAccess ? 'With' : 'From' }}</span>
          <div v-show="!isDirectAccess" class="row">
            <label v-for="item in databaseAccessOptionsNJ" :key="item.id" class="type_many">
              <input v-model="qApplied" name="DatabaseAccess" type="radio" :value="item.val" />
              {{item.text}}
            </label>
          </div>
        </div>
        <div class="column align_end">
          <div class="column align_end">
            <label v-if="isTabTypeP6" class="type_gray alignself_start type_trans20minus">SQL Group (Level) Query</label>
            <label v-else class="type_gray alignself_start type_trans20minus">SQL Query {{ isDirectAccess ? 'Extended' : 'Main' }}</label>
            <div justify="center">
              <v-btn class="type_openTextarea" @click.stop="exp.Q = true">{{ getQueryParts('qDataset') }}</v-btn>
              <j-modal-scripter 
                v-model="qDataset" 
                title="SQL Query"
                sub-title="Access Database"
                :message="sqlDatasetMessage"
                :opened="exp.Q"
                :valid="sqlDatasetValidated"
                @close="exp.Q=false;"
                @save="exp.Q=false;onSave();"
                @validate="onValidate('qDataset')"
              />
            </div>
          </div>
          <div v-if="!isDirectAccess" class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">Stored Procedure</label>
            <div justify="center">
              <v-btn class="type_openTextarea" @click.stop="exp.S=true">T-SQL Procedure Script</v-btn>
              <j-modal-scripter 
                v-model="qSp" 
                title="Stored Procedure"
                sub-title="Access Database"
                :message="spMessage"
                :opened="exp.S"
                :valid="spValidated"
                @close="exp.S=false;"
                @save="exp.S=false;onSave();"
                @validate="onValidate('qSp')"
              />
            </div>
          </div>
        </div>
        <div v-if="isTabTypeDatatable">
          <v-spacer style="height: 10px;"></v-spacer>
          <div class="type_common_input">
            <span class="label">Summary Query</span>
          </div>
          <div class="column align_end">
            <div class="column align_end">
              <div justify="center">
                <v-btn class="type_openTextarea" @click.stop="exp.T = true">{{ getQueryParts('qSummary') }}</v-btn>
                <j-modal-scripter 
                  v-model="qSummary" 
                  title="Summary Query"
                  sub-title="Access Database"
                  :message="sqlSummaryMessage"
                  :opened="exp.T"
                  :valid="sqlSummaryValidated"
                  @close="exp.T=false;"
                  @save="onSave();exp.T=false;"
                  @validate="onValidate('qSummary')"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isDirectAccess">
          <div class="type_common_input">
            <span class="label">{{ isTabTypeP6 ? 'SQL Activity Query' : 'Total Rows Query' }}</span>
          </div>
          <div class="column align_end">
            <div class="column align_end">
              <div justify="center">
                <v-btn class="type_openTextarea" @click.stop="exp.R = true">{{ getQueryParts('qTotalRows') }}</v-btn>
                <j-modal-scripter 
                  v-model="qTotalRows" 
                  :title="isTabTypeP6 ? 'SQL Activity Query' : 'Total Rows Query'"
                  sub-title="Access Database"
                  :message="validated.qTotalRows.message"
                  :opened="exp.R"
                  :valid="validated.qTotalRows.valid"
                  @close="exp.R=false;"
                  @save="onSave();exp.R=false;"
                  @validate="onValidate('qTotalRows')"
                />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Configuration
        <a class="close" v-on:click="collapsed['config'] = !collapsed['config']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['config']">
        <div class="type_common_input">
          <label>Version</label>
          <input v-model="version" readonly type="text"  class="type70" />
        </div>
        <div class="column align_end">
          <label class="alignself_start">JSON Properties</label>
          <div justify="center">
            <v-btn @click.stop="exp.J=true" class="type_openTextarea">{{ getQueryParts('jsonProps') }}</v-btn>
            <j-modal-scripter 
              v-model="jsonProps" 
              sub-title=""
              title="JSON Properties"
              :message="jsonMessage"
              :opened="exp.J"
              :valid="jsonValidated"
              @close="exp.J=false;"
              @save="exp.J=false;onSave();"
              @validate="onValidate('jsonProps')"
            />
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService, SystemCodeService, SystemValuesService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-database',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  props: {
    type: String
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemCodeService: null,
    systemValuesService: null,

    // rules -------------------------
    codeRules: [v => (!!v && v != 'ALL') || "Required"],
    defaultRules: [v => !!v || "Required"],

    // general -----------------------
    collapsed: {
      source: true,
      import: true,
      config: true
    },
    exp: {
      J: false,
      Q: false,
      S: false,
      T: false,
      R: false,
    },
    validated: {
      jsonProps: { valid: true, message: '' },
      qSummary: { valid: true, message: '' },
      qDataset: { valid: true, message: '' },
      qSp: { valid: true, message: '' },
      qTotalRows: { valid: true, message: '' },
    },

    chartReference: '',
    chartReferenceOptions: [],
    databaseOptions: [],
    tableOptions: [],
    jsonPropsPrev: [],

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),
    ...mapGetters(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['database']),

    jsonValidated() { return this.validated.jsonProps.valid },
    jsonMessage() { return this.validated.jsonProps.message },
    sqlSummaryValidated() { return this.validated.qSummary.valid },
    sqlSummaryMessage() { return this.validated.qSummary.message },
    sqlDatasetValidated() { return this.validated.qDataset.valid },
    sqlDatasetMessage() { return this.validated.qDataset.message },
    spValidated() { return this.validated.qSp.valid },
    spMessage() { return this.validated.qSp.message },

    isDirectAccess() { return this.directAccess == 'Y' ? true : false },
    isTabTypeDatatable() { return this.type.includes('datatable') ? true : false },
    isTabTypeP6() { return this.type  == 'datatable-P6' ? true : false },
    isTabTypeDashboard() { return this.type == 'dashboard' ? true : false },

    directAccess: {
      get() { return this.database.directAccess },
      set(val) { this.setDatagridItem({ directAccess: val })}
    },
    dbName: {
      get() { return this.database.dbName },
      set(val) { this.setDatagridItem({ dbName: val })}
    },
    schemaName: {
      get() { return this.database.schemaName },
      set(val) { this.setDatagridItem({ schemaName: val })}
    },
    tableName: {
      get() { return this.database.tableName },
      set(val) { this.setDatagridItem({ tableName: val })}
    },
    qApplied: {
      get() { return this.database.qApplied },
      set(val) { this.setDatagridItem({ qApplied: val })}
    },
    qSummary: {
      get() { return this.database.qSummary },
      set(val) { this.setDatagridItem({ qSummary: val })}
    },
    qDataset: {
      get() { return this.database.qDataset },
      set(val) { this.setDatagridItem({ qDataset: val })}
    },
    qSp: {
      get() { return this.database.qSp },
      set(val) { this.setDatagridItem({ qSp: val })}
    },
    qTotalRows: {
      get() { return this.database.qTotalRows },
      set(val) { this.setDatagridItem({ qTotalRows: val })}
    },
    version: {
      get() { return this.database.version },
      set(val) { this.setDatagridItem({ version: val })}
    },
    jsonProps: {
      get() { return this.database.jsonProps },
      set(val) { this.setDatagridItem({ jsonProps: val })}
    },
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemCodeService = new SystemCodeService()
    this.systemValuesService = new SystemValuesService()
  },
  mounted() {
    this.systemValuesService.sysValuesDatabases(res => {
      if (!res) this.databaseOptions = []
      else this.databaseOptions = res
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, [
      'setDatagridItem', 'setPreviewItems', 'setPreviewSummaryData'
    ]),

    databaseChanged(db) {
      this.systemValuesService.sysValuesTables(db, res => {
        if (!res) this.tableOptions = []
        else this.tableOptions = res.sort((a, b) => {
          return (
            `${a.schemaName}.${a.tableName}`.toLowerCase() > 
            `${b.schemaName}.${b.tableName}`.toLowerCase() ? 
            1 : -1
          )
        })
      })
    },
    generateJson(dataSource) {
      let headers = []

      dataSource.forEach((c, i) => {
        if(['idx'].includes(c.columnName.toLowerCase())) return
        
        if(this.isTabTypeDatatable) {
          let h_ = { 
            type: 'text', 
            text: c.columnDesc, 
            value: c.columnName, 
            width: 85, 
            visible: true
          }
          if(this.directAccess == 'Y') h_.tSql = ''
          headers.push(h_)
          
        } else headers.push({ 
          text: c.columnDesc,
          value: c.columnName,
          filterable: false,
          filterSource: '',
          filterOption: 'mselect',
          filterOrder: 0,
          filterChain: [],
          filterChainOrder: 0
        })
      })
      if(this.isTabTypeDatatable) headers.push({
        type: 'spacer',
        text: '',
        visible: true,
      })
      
      let jsonTemplate = JSON.stringify(headers, null, 4)
      this.jsonProps = jsonTemplate
    },
    tableChanged(table) {
      let tableOption_ = this.tableOptions.find(option => option.tableName == table)
      if(!tableOption_) return

      this.schemaName = tableOption_.schemaName
      this.systemValuesService.sysValuesColumns(this.dbName, table, res => { this.generateJson(res) })
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(typeName) {
      if(typeName == 'jsonProps') {
        this.validated.jsonProps = this.validateJson(this.jsonProps ,true)
        if(this.validated.jsonProps.valid) this.jsonProps = this.validated.jsonProps.json

      } else {
        if(typeName == 'qDataset') {
          let upper_ = this[typeName].toUpperCase()

          if(this.directAccess == 'Y') {
            if(upper_.indexOf('SELECT') > 0 || upper_.indexOf('FROM') > 0) {
              this.validated[typeName] = {
                valid: false,
                message: "In the Extended Query,\n'SELECT | FROM' statement cannot be composed."
              }
              return
            }
            var sqlExtended_ = `SELECT * FROM [${this.dbName}].[${this.schemaName}].[${this.tableName}] ${this[typeName]}`

          } else {
            if(upper_.indexOf('SELECT') < 0 || upper_.indexOf('FROM') < 0) {
              this.validated[typeName] = {
                valid: false,
                message: "In the Main Query,\n'SELECT | FROM' statement should be composed."
              }
              return
            }
          }
        }

        this.validateSql(sqlExtended_ || this[typeName], 'Y', 'Y').then(res => {
          this.validated[typeName] = {
            valid: res.valid,
            message: res.message
          }

          if(res.valid) {
            if(res.results.jsonString) {
              // JSON formated data result
              let results_ = JSON.parse(res.results.jsonString)

              if(typeName == 'qDataset') this.setPreviewItems(results_)
              else if(typeName == 'qSummary') this.setPreviewSummaryData(results_)
            }

            if(!this.jsonProps) {
              let sql_ = this.extractPureSql(this[typeName])
              let params = new URLSearchParams()
              params.append('queryString', sql_)

              this.systemCodeService.sysColumnsFromQuery(params, (colres) => {
                if(!colres) return
                this.generateJson(colres.map(c => ({ 
                  columnName: c.code,
                  columnDesc: c.name
                })))
              })
            }
          }
        })
      }
    },

    setData(mode) {
      this.validated = {
        jsonProps: { valid: true, message: '' },
        qSummary: { valid: true, message: '' },
        qDataset: { valid: true, message: '' },
        qSp: { valid: true, message: '' },
        qTotalRows: { valid: true, message: '' },
      }

      if(mode == __C.FORM.EDIT_MODE_NEW) {
        // 
      } else {
        // if this.dbName is null/empty/undefinded, cors error will be occurred.
        if(this.dbName) this.databaseChanged(this.dbName)
      }
    }
  }
}
</script>
