import { mapState, mapMutations } from "vuex"
import __C from '@/primitives/_constant_'
import JSysenvTabGeneral from '@/templates/JSysenvTabGeneral'

export default {
  extends: JSysenvTabGeneral,
  name: 'j-sysenv-tab-general',
  computed: {
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['datagridItem']),

  },
  watch: {
    datagridItem: {
      handler(val) {
        if(!val) return
        this.dataItem = val
      },
      deep: true
    }
  },
  created() {
    this.dataItemFunc = this.setDatagridItem
    this.importOptionFunc = this.getImportOptions
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID_ADMIN, ['setDatagridItem']),

    getImportOptions() {
      this.systemCodeService.datatableOptions(this.dataItem.catCode, (res) => {
        this.importOptions = res
      })
    },
  }
}
