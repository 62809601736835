<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Group</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Folding
        <a class="close" v-on:click="collapsed['folding'] = !collapsed['folding']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['folding']">
        <div>
          <div class="type_common_input">
            <label class="type_gray">Column Number</label>
            <input v-model="tableFoldingColNum" class="type30" type="text"/>
          </div>
          <div class="type_common_input type_color_float hide_last_child">
            <label class="type_gray">Border</label>
            <j-color-picker v-model="tableFoldingBorder" ></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        P6 View
        <a class="close" v-on:click="collapsed['p6'] = !collapsed['p6']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['p6']">
        <div>
          <div class="type_common_input">
            <label class="type_gray">Folding</label>
            <input v-model="tableP6ColNum" class="type30" type="text"/>
          </div>
          <div class="type_common_input type_color_float hide_last_child">
            <label class="type_gray">Border</label>
            <j-color-picker v-model="tableP6Border" ></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-sysenv-tab-group',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      folding: true,
      p6: true,
    }
  }),
  watch: {},
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    
    // temp data property -------------------------------
    // --------------------------------------------------
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    tableFoldingColNum: {
      get() { return this.chartItem.tableFoldingColNum },
      set(val) { this.setChartItem({ tableFoldingColNum : val})}
    },
    tableFoldingBorder: {
      get() { return this.chartItem.tableFoldingBorder },
      set(val) { this.setChartItem({ tableFoldingBorder : val})}
    },
    tableP6ColNum: {
      get() { return this.chartItem.tableP6ColNum },
      set(val) { this.setChartItem({ tableP6ColNum : val})}
    },
    tableP6Border: {
      get() { return this.chartItem.tableP6Border },
      set(val) { this.setChartItem({ tableP6Border : val})}
    },

    
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem'])
  }
}
</script>
